<!-- 专项讲解-- 详情 -->
<template>
  <div v-if="dataCont" style="background: rgba(247, 247, 247, 1);padding-right: 40rem">
    <div class="title">
      <div class="title-bottom" style="padding-top: 24rem">
        <div class="flex" style="font-weight: bold">
          <div>
            <span class="span1">讲解教师：</span>
            <span class="span2">{{ dataCont.base.teacher_name }}</span>
          </div>
          <div style="margin-left: 100rem">
            <span class="span1">讲解科目：</span>
            <span class="span2">{{ dataCont.base.subject_name }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-form ref="form" label-width="122rem">
      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem">讲解接收人</div>
        </div>
        <div class="title-bottom">
          <el-form-item label="校区" prop="school_id">
            <p>{{ dataCont.base.school_name }}</p>
          </el-form-item>
          <el-form-item label="年级" prop="grade_id">
            <p>{{ dataCont.base.grade_info }}</p>
          </el-form-item>
          <el-form-item label="班级" prop="class_ids">
            <div>
              <el-tag
                  v-for="(item, index) in dataCont.teaching_info"
                  :key="item"
                  style="margin-right: 10rem">
                {{ item }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item label="选讲解接收人" prop="student_ids">
            <div>
              <el-tag
                  v-for="(item, index) in dataCont.student"
                  :key="item"
                  style="margin-right: 10rem">
                {{ item }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem">讲解内容</div>
        </div>
        <div class="title-bottom">
          <el-form-item label="讲解标题" label-width="82rem" prop="title">
            <p>{{ dataCont.base.title }}</p>
          </el-form-item>
          <el-form-item label="讲解内容" label-width="82rem" prop="content">
            <div v-html="dataCont.base.content"></div>
          </el-form-item>
        </div>
      </div>

      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem"><span class="re">关联知识点</span></div>
        </div>
        <div class="title-bottom">
          <div>
            <div v-for="item in dataCont.knowledgeList">
              <span class="span3">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <div class="title">
      <div class="title-title">
        <div style="margin-top: 19rem;margin-left: 24rem">操作记录</div>
      </div>
      <div class="title-bottom">
        <div style="display: inline-block;margin-right: 60rem;margin-top: 12rem">
          <span class="span1">讲解教师：</span>
          <span class="span2">{{ dataCont.base.teacher_name }}</span>
        </div>
        <div style="display: inline-block;margin-right: 60rem;margin-top: 12rem">
          <span class="span1">讲解时间：</span>
          <span class="span2">{{ dataCont.base.solve_time }}</span>
        </div>
      </div>
    </div>

    <div class="button" style="margin-top: 76rem">
      <template>
        <el-button @click="$router.back()">返回</el-button>
        <el-button type="primary" @click="$router.push('./feedback?id=' + dataCont.base.id)">学生反馈</el-button>
      </template>
    </div>

  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      dataCont: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2('api/question-group/student/group-detail?id=' + this.$route.query.id).then(res => {
        this.dataCont = res.data.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-transfer-panel__body {
  height: 350rem !important;
}

::v-deep .el-transfer-panel__list.is-filterable {
  height: 294rem !important;
}

.title-title {
  font-size: 18rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1rem solid #F7F7F7;
  border-radius: 10rem 10rem 0 0;
  background: #FFF;
  box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24rem;
  //padding-top: 28rem;
  padding-bottom: 24rem;
  padding-left: 32rem;
  padding-right: 20rem;
  border-radius: 0 0 10rem 10rem;
  background: #FFF;
}

.body {
  margin: 24rem 0;

  & .body-title {
    font-size: 18rem;
    font-weight: bold;
    box-sizing: border-box;
    height: 78rem;
    padding-top: 19rem;
    padding-left: 24rem;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1rem solid #F7F7F7;
    border-radius: 10rem 10rem 0 0;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148rem;
    padding: 0 24rem 24rem;
    border-radius: 0 0 10rem 10rem;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14rem;
      font-weight: 400;
      width: 96rem;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220rem;
  height: 60rem;
}

.box-1 {
  width: 25%;
}

.box-2 {
  width: 33.3%;
}

.box-3 {
  width: 50%;
}

.box {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-top: 24rem;

  span {
    margin-right: 30rem;
    color: rgba(0, 0, 0, .8);
  }
}

.knowledge {
  padding-top: 10rem;
  display: flex;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    padding-left: 20rem;
    height: 60rem;
    background-color: #f4f4f4;

    .el-input {
      margin-right: 20rem;
      width: 250rem;

      &:last-child {
        width: 400rem;
      }
    }
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.span3 {
  display: inline-block;
  padding: 6rem 10rem;
  font-size: 14rem;
  border-radius: 6rem;
  background: #f7f7f7;
  line-height: 24rem;
  margin-right: 12rem;
  margin-top: 12rem;
}

.rich-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
}

.span1 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-right: 20rem;
}

.span2 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .8);
}

.describe-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
  font-size: 14rem;
  line-height: 26rem;
}

.re {
  position: relative;

  &:before {
    content: "*";
    color: #f53f3f;
    margin-right: 4rem;
  }
}
</style>
